import "./styles/style.css";
import "./styles/bootstrap.min.css";
import "./styles/slick.css";
import "./styles/slick-theme.css";
import "./styles/aos.css";
import "./styles/feature.css";

import logo from './images/1694838574272__1___1_-removebg-preview.png';
import icon_1 from './images/icons-01.png';
import icon_2 from './images/icons-02.png';
import icon_3 from './images/icons-03.png';
import logos_circle from './images/logos-circle.png';
import rating from './images/rating.png';

import final_home_1st from './images/final-home--1st.png';
import final_home_2nd from './images/final-home--2nd.png';
import final_home_3rd from './images/final-home--3rd.png';
import final_home_4th from './images/final-home--4th.png';
import final_home_5th from './images/final-home--5th.png';

import brand_01 from './images/brand-01.png';
import brand_02 from './images/brand-02.png';
import brand_03 from './images/brand-03.png';
import brand_04 from './images/brand-04.png';
import brand_05 from './images/brand-05.png';
import banner_01 from './images/IMG_1726.jpg';


import portfolio_01 from './images/portfolio-01.jpg';
import portfolio_02 from './images/portfolio-02.jpg';
import portfolio_03 from './images/portfolio-03.jpg';
import portfolio_04 from './images/portfolio-04.jpg';
import portfolio_05 from './images/portfolio-05.jpg';
import portfolio_06 from './images/portfolio-06.jpg';

import blog_big_01 from './images/blog-big-01.jpg';
import blog_02 from './images/blog-02.jpg';
import blog_03 from './images/blog-03.jpg';

import contact1 from './images/contact1.png';
import index_technician from './images/index-technician.png';
import main_demo from './images/main-demo.png';
import home_model_v2 from './images/home-model-v2.png';
import home_consulting from './images/home-consulting.png';
import fashion_designer from './images/fashion-designer.png';
import developer from './images/developer.png';
import instructor_fitness from './images/instructor-fitness.png';
import home_model from './images/home-model.png';
import home_video from './images/home-video.png';
import text_rotet from './images/text-rotet.png';
import index_boxed from './images/index-boxed.png';
import home_sticky from './images/home-sticky.png';
import index_bg_image from './images/index-bg-image.png';
import front_end from './images/front-end.png';

import coming_soon from './images/coming-soon.png';
import main_demo_white_version from './images/main-demo-white-version.png';
import index_technician_white_version from './images/index-technician-white-version.png';
import home_model_v2_white from './images/home-model-v2-white.png';
import home_consulting_white_version from './images/home-consulting-white-version.png';
import fashion_designer_white_version from './images/fashion-designer-white-version.png';
import developer_white_version from './images/developer-white-version.png';
import instructor_fitness_white_version from './images/instructor-fitness-white-version.png';

import home_model_white_version from './images/home-model-white-version.png';
import home_video_white_version from './images/home-video-white-version.png';
import text_rotet_white_version from './images/text-rotet-white-version.png';
import index_boxed_white_version from './images/index-boxed-white-version.png';
import home_sticky_white_version from './images/home-sticky-white-version.png';

import index_bg_image_white_version from './images/index-bg-image-white-version.png';
import front_end_white_version from './images/front-end-white-version.png';
import logo_vertical_dark from './images/logo-vertical-dark.png';
import linked_in_icon from './images/linkedin.svg';
import facebook_icon from './images/github-mark.png';
import instagram_icon from './images/instagram.svg';
import python_icon from './images/python.png';
import javascript_icon from './images/icons8-javascript.svg';
import aws_icon from './images/icons8-amazon-web-services.svg';
import deployment_img from './images/bg-image-11.jpg';


function Body() {
	return (
		<>
			{/* Start Header */}
			<header className="rn-header haeder-default black-logo-version header--fixed header--sticky">
				<div className="header-wrapper rn-popup-mobile-menu m--0 row align-items-center">
					{/* Start Header Left */}
					<div className="col-lg-2 col-6">
						<div className="header-left">
							<div className="logo">
								<a href="index.html">
									<img src={logo} alt="logo" width="60px" />
								</a>
							</div>
						</div>
					</div>
					{/* End Header Left */}
					{/* Start Header Center */}
					<div className="col-lg-10 col-6">
						<div className="header-center">
							<nav
								id="sideNav"
								className="mainmenu-nav navbar-example2 d-none d-xl-block onepagenav"
							>
								{/* Start Mainmanu Nav */}
								<ul className="primary-menu nav nav-pills">
									<li className="nav-item current">
										<a className="nav-link" href="#home">
											Home
										</a>
									</li>
									<li className="nav-item">
										<a className="nav-link" href="#features">
											Features
										</a>
									</li>
									<li className="nav-item">
										<a className="nav-link" href="#portfolio">
											Portfolio
										</a>
									</li>
									<li className="nav-item">
										<a className="nav-link" href="#resume">
											Resume
										</a>
									</li>

									<li className="nav-item">
										<a className="nav-link" href="#blog">
											blog
										</a>
									</li>
									<li className="nav-item">
										<a className="nav-link" href="#contacts">
											Contact
										</a>
									</li>
								</ul>
								{/* End Mainmanu Nav */}
							</nav>
							{/* Start Header Right  */}
							<div className="header-right">
								<a target="_blank" className="rn-btn" href="https://api.whatsapp.com/send?phone=+917889378278&text=Hello Mukhtar Ahmad Zargar, I have an idea need to converted it into business!"><span>Whatsapp</span></a>

								<div className="hamberger-menu d-block d-xl-none">
									<i id="menuBtn" className="feather-menu humberger-menu" />
								</div>
								<div className="close-menu d-block">
									<span className="closeTrigger">
										<i data-feather="x" />
									</span>
								</div>
							</div>
							{/* End Header Right  */}
						</div>
					</div>
					{/* End Header Center */}
				</div>
			</header>
			{/* End Header Area */}
			{/* Start Popup Mobile Menu  */}
			<div className="popup-mobile-menu">
				<div className="inner">
					<div className="menu-top">
						<div className="menu-header">
							<a className="logo" href="index.html">
								<img src={logos_circle} alt="Personal Portfolio" />
							</a>
							<div className="close-button">
								<button className="close-menu-activation close">
									<i data-feather="x" />
								</button>
							</div>
						</div>
						<p className="discription">
							Inbio is a personal portfolio template. You can customize all.
						</p>
					</div>
					<div className="content">
						<ul className="primary-menu nav nav-pills onepagenav">
							<li className="nav-item current">
								<a className="nav-link smoth-animation active" href="#home">
									Home
								</a>
							</li>
							<li className="nav-item">
								<a className="nav-link smoth-animation" href="#features">
									Features
								</a>
							</li>
							<li className="nav-item">
								<a className="nav-link smoth-animation" href="#portfolio">
									Portfolio
								</a>
							</li>
							<li className="nav-item">
								<a className="nav-link smoth-animation" href="#resume">
									Resume
								</a>
							</li>
							<li className="nav-item">
								<a className="nav-link smoth-animation" href="#clients">
									Clients
								</a>
							</li>
							<li className="nav-item">
								<a className="nav-link smoth-animation" href="#pricing">
									Pricing
								</a>
							</li>
							<li className="nav-item">
								<a className="nav-link smoth-animation" href="#blog">
									blog
								</a>
							</li>
							<li className="nav-item">
								<a className="nav-link smoth-animation" href="#contacts">
									Contact
								</a>
							</li>
						</ul>
						{/* social sharea area */}
						<div className="social-share-style-1 mt--40">
							<span className="title">find with me</span>
							<ul className="social-share d-flex liststyle">
								<li className="facebook">
									<a href="https://github.com/Maan20">
										<img src={facebook_icon} width="50%" />
									</a>
								</li>
								<li className="instagram">
									<a href="https://www.instagram.com/maaan_g/">
										<img src={instagram_icon} />
									</a>
								</li>
								<li className="linkedin">
									<a href="https://www.linkedin.com/in/mukhtar-ahmad-zargar/">
										<img src={linked_in_icon} />
									</a>
								</li>
							</ul>
						</div>
						{/* end */}
					</div>
				</div>
			</div>
			{/* End Popup Mobile Menu  */}
			<main className="main-page-wrapper">
				{/* Start Slider Area */}
				<div id="home" className="rn-slider-area">
					<div className="slide slider-style-1">
						<div className="container">
							<div className="row row--30 align-items-center">
								<div className="order-2 order-lg-1 col-lg-7 mt_md--50 mt_sm--50 mt_lg--30">
									<div className="content">
										<div className="inner">
											<span className="subtitle">Welcome to my world</span>
											<h1 className="title">
												Hi, I’m <span>Mukhtar Ah.</span>
												<br />
												<span className="header-caption" id="page-top">
													{/* type headline start*/}
													<span className="cd-headline clip is-full-width">
														<span>a </span>
														{/* ROTATING TEXT */}
														<span className="cd-words-wrapper">
															<b className="is-visible">Developer.</b>
															<b className="is-hidden">Professional Coder.</b>
															<b className="is-hidden">Developer.</b>
														</span>
													</span>
													{/* type headline end */}
												</span>
											</h1>
											<div>
												<p className="description">
													Hello! I'm Mukhtar Ahmad Zargar, a passionate and skilled Full Stack Developer with over 4 years of experience in web development. I specialize in a wide range of technologies, including Python, Django, JavaScript, AWS, and more.
													I have a proven track record of creating dynamic and responsive websites and APIs, some of which have been used by mobile apps. My problem-solving skills, attention to detail, and ability to work collaboratively make me a valuable asset to any development team.
													When I'm not coding, you can find me exploring new technologies, working on personal projects, or contributing to open-source communities. I'm always excited to take on new challenges and turn innovative ideas into reality.
												</p>
											</div>
										</div>
										<div className="row">
											<div className="col-lg-6 col-xl-6 col-md-6 col-sm-6 col-12">
												<div className="social-share-inner-left">
													<span className="title">find with me</span>
													<ul className="social-share d-flex liststyle">
														<li className="facebook">
															<a target="_blank" href="https://github.com/Maan20">
																<img src={facebook_icon} width="50%" />
															</a>
														</li>
														<li className="instagram">
															<a target="_blank" href="https://www.instagram.com/maaan_g/">
																<img src={instagram_icon} />
															</a>
														</li>
														<li className="linkedin ">
															<a target="_blank" href="https://www.linkedin.com/in/mukhtar-ahmad-zargar/">
																<img src={linked_in_icon} />
															</a>
														</li>
													</ul>
												</div>
											</div>
											<div className="col-lg-6 col-xl-6 col-md-6 col-sm-6 col-12 mt_mobile--30">
												<div className="skill-share-inner">
													<span className="title">best skill on</span>
													<ul className="skill-share d-flex liststyle">
														<li>
															<img
																src={python_icon}
																alt="Icons Images"
															/>
														</li>
														<li>
															<img
																src={javascript_icon}
																alt="Icons Images"
															/>
														</li>
														<li>
															<img
																src={aws_icon}
																alt="Icons Images"
															/>
														</li>
													</ul>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="order-1 order-lg-2 col-lg-5">
									<div className="thumbnail">
										<div className="inner">
											<img
												src={banner_01}
												alt="Personal Portfolio Images "
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				{/* End Slider Area */}
				{/* Start Service Area */}
				<div
					className="rn-service-area rn-section-gap section-separator"
					id="features"
				>
					<div className="container">
						<div className="row">
							<div className="col-lg-12">
								<div
									className="section-title text-left"
									data-aos="fade-up"
									data-aos-duration={500}
									data-aos-delay={100}
									data-aos-once="true"
								>
									<span className="subtitle">Features</span>
									<h2 className="title">What I Do</h2>
								</div>
							</div>
						</div>
						<div className="row row--25 mt_md--10 mt_sm--10">
							{/* Start Single Service */}
							<div
								data-aos="fade-up"
								data-aos-duration={500}
								data-aos-delay={100}
								data-aos-once="true"
								className="col-lg-6 col-xl-4 col-md-6 col-sm-12 col-12 mt--50 mt_md--30 mt_sm--30"
							>
								<div className="rn-service">
									<div className="inner">
										<div className="icon">
											<i data-feather="menu" />
										</div>
										<div className="content">
											<h4 className="title">
												<a href="#">Full Stack Development</a>
											</h4>
											<p className="description">
												I specialize in end-to-end web development using technologies like Python, Django, and various frontend frameworks. I create robust and scalable solutions for web applications and RESTful APIs using Django REST framework.
											</p>
											<a className="read-more-button" href="#">
												<i className="feather-arrow-right" />
											</a>
										</div>
									</div>
									<a className="over-link" href="#" />
								</div>
							</div>
							{/* End SIngle Service */}
							{/* Start Single Service */}
							<div
								data-aos="fade-up"
								data-aos-duration={500}
								data-aos-delay={300}
								data-aos-once="true"
								className="col-lg-6 col-xl-4 col-md-6 col-sm-12 col-12 mt--50 mt_md--30 mt_sm--30"
							>
								<div className="rn-service">
									<div className="inner">
										<div className="icon">
											<i data-feather="book-open" />
										</div>
										<div className="content">
											<h4 className="title">
												<a href="#">Database Management</a>
											</h4>
											<p className="description">
												{" "}
												I have extensive experience working with relational databases like PostgreSQL, MySQL, and SQL Server. I design efficient database schemas and optimize queries for high-performance applications.
											</p>
											<a className="read-more-button" href="#">
												<i className="feather-arrow-right" />
											</a>
										</div>
									</div>
									<a className="over-link" href="#" />
								</div>
							</div>
							{/* End SIngle Service */}
							{/* Start Single Service */}
							<div
								data-aos="fade-up"
								data-aos-duration={500}
								data-aos-delay={500}
								data-aos-once="true"
								className="col-lg-6 col-xl-4 col-md-6 col-sm-12 col-12 mt--50 mt_md--30 mt_sm--30"
							>
								<div className="rn-service">
									<div className="inner">
										<div className="icon">
											<i data-feather="tv" />
										</div>
										<div className="content">
											<h4 className="title">
												<a href="#">Deployment</a>
											</h4>
											<p className="description">
												I deploy and manage web applications on cloud platforms like AWS. I'm experienced in configuring servers, setting up databases, and ensuring the security and scalability of cloud-hosted applications.
											</p>
											<a className="read-more-button" href="#">
												<i className="feather-arrow-right" />
											</a>
										</div>
									</div>
									<a className="over-link" href="#" />
								</div>
							</div>
							{/* End SIngle Service */}
							{/* Start Single Service */}
							<div
								data-aos="fade-up"
								data-aos-duration={500}
								data-aos-delay={100}
								data-aos-once="true"
								className="col-lg-6 col-xl-4 col-md-6 col-sm-12 col-12 mt--50 mt_md--30 mt_sm--30"
							>
								<div className="rn-service">
									<div className="inner">
										<div className="icon">
											<i data-feather="twitch" />
										</div>
										<div className="content">
											<h4 className="title">
												<a href="#">API Development</a>
											</h4>
											<p className="description">
												I design and build RESTful APIs using Django REST framework, ensuring efficient communication between web and mobile applications. My APIs are well-documented and follow best practices.
											</p>
											<a className="read-more-button" href="#">
												<i className="feather-arrow-right" />
											</a>
										</div>
									</div>
									<a className="over-link" href="#" />
								</div>
							</div>
							{/* End SIngle Service */}
							{/* Start Single Service */}
							<div
								data-aos="fade-up"
								data-aos-duration={500}
								data-aos-delay={300}
								data-aos-once="true"
								className="col-lg-6 col-xl-4 col-md-6 col-sm-12 col-12 mt--50 mt_md--30 mt_sm--30"
							>
								<div className="rn-service">
									<div className="inner">
										<div className="icon">
											<i data-feather="wifi" />
										</div>
										<div className="content">
											<h4 className="title">
												<a href="#">Frontend Development</a>
											</h4>
											<p className="description">
												I create responsive and user-friendly frontend interfaces using HTML, CSS, JavaScript, and popular frontend libraries like Bootstrap and jQuery. I focus on delivering a seamless user experience.
											</p>
											<a className="read-more-button" href="#">
												<i className="feather-arrow-right" />
											</a>
										</div>
									</div>
									<a className="over-link" href="#" />
								</div>
							</div>
							{/* End SIngle Service */}
							{/* Start Single Service */}
							<div
								data-aos="fade-up"
								data-aos-duration={500}
								data-aos-delay={500}
								data-aos-once="true"
								className="col-lg-6 col-xl-4 col-md-6 col-sm-12 col-12 mt--50 mt_md--30 mt_sm--30"
							>
								<div className="rn-service">
									<div className="inner">
										<div className="icon">
											<i data-feather="slack" />
										</div>
										<div className="content">
											<h4 className="title">
												<a href="#">API Design and Integration</a>
											</h4>
											<p className="description">
												{" "}
												I design and develop RESTful APIs to power mobile apps and web services. I'm skilled in integrating third-party APIs for functionalities like payment gateways, social media, and geolocation.
											</p>
											<a className="read-more-button" href="#">
												<i className="feather-arrow-right" />
											</a>
										</div>
									</div>
									<a className="over-link" href="#" />
								</div>
							</div>
							{/* End SIngle Service */}
						</div>
					</div>
				</div>
				{/* End Service Area  */}
				{/* Start Portfolio Area */}
				<div
					className="rn-portfolio-area rn-section-gap section-separator"
					id="portfolio"
				>
					<div className="container">
						<div className="row">
							<div className="col-lg-12">
								<div className="section-title text-center">
									<span className="subtitle">
										Visit my portfolio and keep your feedback
									</span>
									<h2 className="title">My Portfolio</h2>
								</div>
							</div>
						</div>
						<div className="row row--25 mt--10 mt_md--10 mt_sm--10">
							{/* Start Single Portfolio */}
							<div
								data-aos="fade-up"
								data-aos-delay={100}
								data-aos-once="true"
								className="col-lg-6 col-xl-4 col-md-6 col-12 mt--50 mt_md--30 mt_sm--30"
							>
								<div
									className="rn-portfolio"
									data-bs-toggle="modal"
									data-bs-target="#exampleModalCenter"
								>
									<div className="inner">
										<div className="thumbnail">
											<a href="javascript:void(0)">
												<img
													src={portfolio_01}
													alt="Personal Portfolio Images"
												/>
											</a>
										</div>
										<div className="content">
											<div className="category-info">
												<div className="category-list">
													<a href="javascript:void(0)">Development</a>
												</div>
												<div className="meta">
													<span>
														<a href="javascript:void(0)">
															<i className="feather-heart" />
														</a>
														600
													</span>
												</div>
											</div>
											<h4 className="title">
												<a href="javascript:void(0)">
													Development to Deployment of E-commerce Websites
													<i className="feather-arrow-up-right" />
												</a>
											</h4>
										</div>
									</div>
								</div>
							</div>
							{/* End Single Portfolio */}
							{/* Start Single Portfolio */}
							<div
								data-aos="fade-up"
								data-aos-delay={300}
								data-aos-once="true"
								className="col-lg-6 col-xl-4 col-md-6 col-12 mt--50 mt_md--30 mt_sm--30"
							>
								<div
									className="rn-portfolio"
									data-bs-toggle="modal"
									data-bs-target="#exampleModalCenter"
								>
									<div className="inner">
										<div className="thumbnail">
											<a href="javascript:void(0)">
												<img
													src={portfolio_02}
													alt="Personal Portfolio Images"
												/>
											</a>
										</div>
										<div className="content">
											<div className="category-info">
												<div className="category-list">
													<a href="javascript:void(0)">Application</a>
												</div>
												<div className="meta">
													<span>
														<a href="javascript:void(0)">
															<i className="feather-heart" />
														</a>
														750
													</span>
												</div>
											</div>
											<h4 className="title">
												<a href="javascript:void(0)">
													REST API's for Multi-Purpose Applications
													<i className="feather-arrow-up-right" />
												</a>
											</h4>
										</div>
									</div>
								</div>
							</div>
							{/* End Single Portfolio */}
							{/* Start Single Portfolio */}
							<div
								data-aos="fade-up"
								data-aos-delay={500}
								data-aos-once="true"
								className="col-lg-6 col-xl-4 col-md-6 col-12 mt--50 mt_md--30 mt_sm--30"
							>
								<div
									className="rn-portfolio"
									data-bs-toggle="modal"
									data-bs-target="#exampleModalCenter"
								>
									<div className="inner">
										<div className="thumbnail">
											<a href="javascript:void(0)">
												<img
													src={portfolio_03}
													alt="Personal Portfolio Images"
												/>
											</a>
										</div>
										<div className="content">
											<div className="category-info">
												<div className="category-list">
													<a href="javascript:void(0)">Websites</a>
												</div>
												<div className="meta">
													<span>
														<a href="javascript:void(0)">
															<i className="feather-heart" />
														</a>
														630
													</span>
												</div>
											</div>
											<h4 className="title">
												<a href="javascript:void(0)">
													Job Search and ResumeBuild Websites with API's
													<i className="feather-arrow-up-right" />
												</a>
											</h4>
										</div>
									</div>
								</div>
							</div>
							{/* End Single Portfolio */}
							{/* Start Single Portfolio */}
							<div
								data-aos="fade-up"
								data-aos-delay={100}
								data-aos-once="true"
								className="col-lg-6 col-xl-4 col-md-6 col-12 mt--50 mt_md--30 mt_sm--30"
							>
								<div
									className="rn-portfolio"
									data-bs-toggle="modal"
									data-bs-target="#exampleModalCenter"
								>
									<div className="inner">
										<div className="thumbnail">
											<a href="javascript:void(0)">
												<img
													src={portfolio_04}
													alt="Personal Portfolio Images"
												/>
											</a>
										</div>
										<div className="content">
											<div className="category-info">
												<div className="category-list">
													<a href="javascript:void(0)">Admin Panels</a>
												</div>
												<div className="meta">
													<span>
														<a href="javascript:void(0)">
															<i className="feather-heart" />
														</a>
														360
													</span>
												</div>
											</div>
											<h4 className="title">
												<a href="javascript:void(0)">
													Security and Functionality of Admin Panels.
													<i className="feather-arrow-up-right" />
												</a>
											</h4>
										</div>
									</div>
								</div>
							</div>
							{/* End Single Portfolio */}
							{/* Start Single Portfolio */}
							<div
								data-aos="fade-up"
								data-aos-delay={300}
								data-aos-once="true"
								className="col-lg-6 col-xl-4 col-md-6 col-12 mt--50 mt_md--30 mt_sm--30"
							>
								<div
									className="rn-portfolio"
									data-bs-toggle="modal"
									data-bs-target="#exampleModalCenter"
								>
									<div className="inner">
										<div className="thumbnail">
											<a href="javascript:void(0)">
												<img
													src={portfolio_05}
													alt="Personal Portfolio Images"
												/>
											</a>
										</div>
										<div className="content">
											<div className="category-info">
												<div className="category-list">
													<a href="javascript:void(0)">Web Design</a>
												</div>
												<div className="meta">
													<span>
														<a href="javascript:void(0)">
															<i className="feather-heart" />
														</a>
														280
													</span>
												</div>
											</div>
											<h4 className="title">
												<a href="javascript:void(0)">
													Design for Technology & Services
													<i className="feather-arrow-up-right" />
												</a>
											</h4>
										</div>
									</div>
								</div>
							</div>
							{/* End Single Portfolio */}
							{/* Start Single Portfolio */}
							<div
								data-aos="fade-up"
								data-aos-delay={500}
								data-aos-once="true"
								className="col-lg-6 col-xl-4 col-md-6 col-12 mt--50 mt_md--30 mt_sm--30"
							>
								<div
									className="rn-portfolio"
									data-bs-toggle="modal"
									data-bs-target="#exampleModalCenter"
								>
									<div className="inner">
										<div className="thumbnail">
											<a href="javascript:void(0)">
												<img
													src={portfolio_06}
													alt="Personal Portfolio Images"
												/>
											</a>
										</div>
										<div className="content">
											<div className="category-info">
												<div className="category-list">
													<a href="javascript:void(0)">Deployment</a>
												</div>
												<div className="meta">
													<span>
														<a href="javascript:void(0)">
															<i className="feather-heart" />
														</a>
														690
													</span>
												</div>
											</div>
											<h4 className="title">
												<a href="javascript:void(0)">
													Deployment of Projects using NGINX and AWS
													<i className="feather-arrow-up-right" />
												</a>
											</h4>
										</div>
									</div>
								</div>
							</div>
							{/* End Single Portfolio */}
						</div>
					</div>
				</div>
				{/* End portfolio Area */}
				{/* Start Resume Area */}
				<div
					className="rn-resume-area rn-section-gap section-separator"
					id="resume"
				>
					<div className="container">
						<div className="row">
							<div className="col-lg-12">
								<div className="section-title text-center">
									<span className="subtitle">4 Years of Experience</span>
									<h2 className="title">My Resume</h2>
								</div>
							</div>
						</div>
						<div className="row mt--45">
							<div className="col-lg-12">
								<ul
									className="rn-nav-list nav nav-tabs"
									id="myTabs"
									role="tablist"
								>
									<li className="nav-item">
										<a
											className="nav-link active"
											id="education-tab"
											data-bs-toggle="tab"
											href="#education"
											role="tab"
											aria-controls="education"
											aria-selected="true"
										>
											education
										</a>
									</li>
									<li className="nav-item">
										<a
											className="nav-link"
											id="professional-tab"
											data-bs-toggle="tab"
											href="#professional"
											role="tab"
											aria-controls="professional"
											aria-selected="false"
										>
											professional Skills
										</a>
									</li>
									<li className="nav-item">
										<a
											className="nav-link"
											id="experience-tab"
											data-bs-toggle="tab"
											href="#experience"
											role="tab"
											aria-controls="experience"
											aria-selected="false"
										>
											experience
										</a>
									</li>

								</ul>
								{/* Start Tab Content Wrapper  */}
								<div className="rn-nav-content tab-content" id="myTabContents">
									{/* Start Single Tab  */}
									<div
										className="tab-pane show active fade single-tab-area"
										id="education"
										role="tabpanel"
										aria-labelledby="education-tab"
									>
										<div className="personal-experience-inner mt--40">
											<div className="row">
												{/* Start Skill List Area  */}
												<div className="col-lg-6 col-md-12 col-12">
													<div className="content">
														<span className="subtitle">2016 - 2021</span>
														<h4 className="maintitle">Education Quality</h4>
														<div className="experience-list">
															{/* Start Single List  */}
															<div className="resume-single-list">
																<div className="inner">
																	<div className="heading">
																		<div className="title">
																			<h4> Internship in Django Python Development</h4>
																			<span>
																				6 months - WindowsIT, Gurugram (2021-2021)
																			</span>
																		</div>
																		<div className="date-of-time">
																			<span>4.1/5</span>
																		</div>
																	</div>
																	<p className="description">
																		This internship exposed me to the collaborative nature of software development, as I worked alongside experienced developers and learned best practices in the industry. It was a valuable stepping stone that allowed me to transition from a learner to a professional in the field of web development.
																	</p>
																</div>
															</div>
															{/* End Single List  */}

															{/* Start Single List  */}
															<div className="resume-single-list">
																<div className="inner">
																	<div className="heading">
																		<div className="title">
																			<h4>Master of Computer Application</h4>
																			<span>
																				Punjabi University Patiala (2019 - 2021)
																			</span>
																		</div>
																		<div className="date-of-time">
																			<span>98.5 %</span>
																		</div>
																	</div>
																	<p className="description">
																		I studied various programming languages, data structures, algorithms, and software development methodologies. Additionally, I worked on several practical projects, which enhanced my problem-solving skills and programming abilities.
																	</p>
																</div>
															</div>
															{/* End Single List  */}

															{/* Start Single List  */}
															<div className="resume-single-list">
																<div className="inner">
																	<div className="heading">
																		<div className="title">
																			<h4> Bachelor of Computer Application</h4>
																			<span>
																				Punjabi University Patiala (2016 - 2019)
																			</span>
																		</div>
																		<div className="date-of-time">
																			<span>85.2 %</span>
																		</div>
																	</div>
																	<p className="description">
																		This interdisciplinary background has been valuable in my career, allowing me to approach problem-solving from multiple perspectives and communicate technical concepts clearly to non-technical stakeholders.
																	</p>
																</div>
															</div>
															{/* End Single List  */}

														</div>
													</div>
												</div>
												{/* End Skill List Area  */}

												{/* Start Skill List Area 2nd  */}
												<div className="col-lg-6 col-md-12 col-12 mt_md--60 mt_sm--60">
													<div className="content">
														<span className="subtitle">2004 - 2015</span>
														<h4 className="maintitle">Schooling</h4>
														<div className="experience-list">
															{/* Start Single List  */}
															<div className="resume-single-list">
																<div className="inner">
																	<div className="heading">
																		<div className="title">
																			<h4>Higher Seconday (+2)</h4>
																			<span>
																				J&K Bose (2014 - 2015)
																			</span>
																		</div>
																		<div className="date-of-time">
																			<span>92 %</span>
																		</div>
																	</div>
																	<p className="description">
																		During my higher secondary education (12th grade), I focused on science subjects, which laid the foundation for my future studies in computer science. I excelled in subjects such as mathematics and physics, which helped develop my analytical and mathematical skills.
																	</p>
																</div>
															</div>
															{/* End Single List  */}
															{/* Start Single List  */}
															<div className="resume-single-list">
																<div className="inner">
																	<div className="heading">
																		<div className="title">
																			<h4>Secondary School</h4>
																			<span>J&K Bose (2012 - 2013)</span>
																		</div>
																		<div className="date-of-time">
																			<span>4.70/5</span>
																		</div>
																	</div>
																	<p className="description">
																		During this period, I gained proficiency in subjects such as mathematics, science, and language arts. These formative years not only shaped my academic capabilities but also instilled a sense of curiosity and a desire to learn, which continue to drive my personal and professional growth.
																	</p>
																</div>
															</div>
															{/* End Single List  */}
															{/* Start Single List  */}
															<div className="resume-single-list">
																<div className="inner">
																	<div className="heading">
																		<div className="title">
																			<h4>Lower Schooling</h4>
																			<span>
																				Dehli Pubic School Srinagar (2004-2011)
																			</span>
																		</div>
																		<div className="date-of-time">
																			<span>4.95/5</span>
																		</div>
																	</div>
																	<p className="description">
																		This phase of my education was marked by a sense of wonder and exploration, setting the stage for future learning endeavors. My primary schooling provided a nurturing environment that fostered a love for learning and paved the way for my educational journey.


																	</p>
																</div>
															</div>
															{/* End Single List  */}



														</div>
													</div>
												</div>
												{/* End Skill List Area  */}
											</div>
										</div>
									</div>
									{/* End Single Tab  */}
									{/* Start Single Tab  */}
									<div
										className="tab-pane fade "
										id="professional"
										role="tabpanel"
										aria-labelledby="professional-tab"
									>
										<div className="personal-experience-inner mt--40">
											<div className="row row--40">
												{/* Start Single Progressbar  */}
												<div className="col-lg-6 col-md-6 col-12">
													<div className="progress-wrapper">
														<div className="content">
															<span className="subtitle">Features</span>
															<h4 className="maintitle">Development Skills</h4>
															{/* Start Single Progress Charts */}
															<div className="progress-charts">
																<h6 className="heading heading-h6">
																	Python
																</h6>
																<div className="progress">
																	<div
																		className="progress-bar wow fadeInLeft"
																		data-wow-duration="0.5s"
																		data-wow-delay=".3s"
																		role="progressbar"
																		style={{ width: "90%" }}
																		aria-valuenow={85}
																		aria-valuemin={0}
																		aria-valuemax={100}
																	>
																		<span className="percent-label">90%</span>
																	</div>
																</div>
															</div>
															{/* End Single Progress Charts */}
															{/* Start Single Progress Charts */}
															<div className="progress-charts">
																<h6 className="heading heading-h6">Django</h6>
																<div className="progress">
																	<div
																		className="progress-bar wow fadeInLeft"
																		data-wow-duration="0.6s"
																		data-wow-delay=".4s"
																		role="progressbar"
																		style={{ width: "95%" }}
																		aria-valuenow={85}
																		aria-valuemin={0}
																		aria-valuemax={100}
																	>
																		<span className="percent-label">95%</span>
																	</div>
																</div>
															</div>
															{/* End Single Progress Charts */}
															{/* Start Single Progress Charts */}
															<div className="progress-charts">
																<h6 className="heading heading-h6">Javascript</h6>
																<div className="progress">
																	<div
																		className="progress-bar wow fadeInLeft"
																		data-wow-duration="0.7s"
																		data-wow-delay=".5s"
																		role="progressbar"
																		style={{ width: "80%" }}
																		aria-valuenow={85}
																		aria-valuemin={0}
																		aria-valuemax={100}
																	>
																		<span className="percent-label">80%</span>
																	</div>
																</div>
															</div>
															{/* End Single Progress Charts */}
															{/* Start Single Progress Charts */}
															<div className="progress-charts">
																<h6 className="heading heading-h6">
																	HTML & CSS
																</h6>
																<div className="progress">
																	<div
																		className="progress-bar wow fadeInLeft"
																		data-wow-duration="0.8s"
																		data-wow-delay=".6s"
																		role="progressbar"
																		style={{ width: "60%" }}
																		aria-valuenow={85}
																		aria-valuemin={0}
																		aria-valuemax={100}
																	>
																		<span className="percent-label">60%</span>
																	</div>
																</div>
															</div>
															{/* End Single Progress Charts */}
															{/* Start Single Progress Charts */}
															<div className="progress-charts">
																<h6 className="heading heading-h6">React Js</h6>
																<div className="progress">
																	<div
																		className="progress-bar wow fadeInLeft"
																		data-wow-duration="0.9s"
																		data-wow-delay=".7s"
																		role="progressbar"
																		style={{ width: "50%" }}
																		aria-valuenow={85}
																		aria-valuemin={0}
																		aria-valuemax={100}
																	>
																		<span className="percent-label">50%</span>
																	</div>
																</div>
															</div>
															{/* End Single Progress Charts */}
														</div>
													</div>
												</div>
												{/* End Single Progressbar  */}
												{/* Start Single Progressbar  */}
												<div className="col-lg-6 col-md-6 col-12 mt_sm--60">
													<div className="progress-wrapper">
														<div className="content">
															<span className="subtitle">Features</span>
															<h4 className="maintitle">Other Skill</h4>
															{/* Start Single Progress Charts */}
															<div className="progress-charts">
																<h6 className="heading heading-h6">AWS</h6>
																<div className="progress">
																	<div
																		className="progress-bar wow fadeInLeft"
																		data-wow-duration="0.5s"
																		data-wow-delay=".3s"
																		role="progressbar"
																		style={{ width: "90%" }}
																		aria-valuenow={85}
																		aria-valuemin={0}
																		aria-valuemax={100}
																	>
																		<span className="percent-label">90%</span>
																	</div>
																</div>
															</div>
															{/* End Single Progress Charts */}
															{/* Start Single Progress Charts */}
															<div className="progress-charts">
																<h6 className="heading heading-h6">Domains Handling</h6>
																<div className="progress">
																	<div
																		className="progress-bar wow fadeInLeft"
																		data-wow-duration="0.6s"
																		data-wow-delay=".4s"
																		role="progressbar"
																		style={{ width: "90%" }}
																		aria-valuenow={85}
																		aria-valuemin={0}
																		aria-valuemax={100}
																	>
																		<span className="percent-label">90%</span>
																	</div>
																</div>
															</div>
															{/* End Single Progress Charts */}
															{/* Start Single Progress Charts */}
															<div className="progress-charts">
																<h6 className="heading heading-h6">
																	JAVASCRIPT
																</h6>
																<div className="progress">
																	<div
																		className="progress-bar wow fadeInLeft"
																		data-wow-duration="0.7s"
																		data-wow-delay=".5s"
																		role="progressbar"
																		style={{ width: "95%" }}
																		aria-valuenow={85}
																		aria-valuemin={0}
																		aria-valuemax={100}
																	>
																		<span className="percent-label">95%</span>
																	</div>
																</div>
															</div>
															{/* End Single Progress Charts */}
															{/* Start Single Progress Charts */}
															<div className="progress-charts">
																<h6 className="heading heading-h6">Payment gateways</h6>
																<div className="progress">
																	<div
																		className="progress-bar wow fadeInLeft"
																		data-wow-duration="0.8s"
																		data-wow-delay=".6s"
																		role="progressbar"
																		style={{ width: "75%" }}
																		aria-valuenow={85}
																		aria-valuemin={0}
																		aria-valuemax={100}
																	>
																		<span className="percent-label">75%</span>
																	</div>
																</div>
															</div>
															{/* End Single Progress Charts */}
															{/* Start Single Progress Charts */}
															<div className="progress-charts">
																<h6 className="heading heading-h6">PLUGIN</h6>
																<div className="progress">
																	<div
																		className="progress-bar wow fadeInLeft"
																		data-wow-duration="0.9s"
																		data-wow-delay=".7s"
																		role="progressbar"
																		style={{ width: "70%" }}
																		aria-valuenow={85}
																		aria-valuemin={0}
																		aria-valuemax={100}
																	>
																		<span className="percent-label">70%</span>
																	</div>
																</div>
															</div>
															{/* End Single Progress Charts */}
														</div>
													</div>
												</div>
												{/* End Single Progressbar  */}
											</div>
										</div>
									</div>
									{/* End Single Tab  */}
									{/* Start Single Tab  */}
									<div
										className="tab-pane fade"
										id="experience"
										role="tabpanel"
										aria-labelledby="experience-tab"
									>
										<div className="personal-experience-inner mt--40">
											<div className="row">
												{/* Start Skill List Area  */}
												<div className="col-lg-6 col-md-12 col-12">
													<div className="content">
														<span className="subtitle">2020 - 2024</span>
														<h4 className="maintitle">Work Experience</h4>
														<div className="experience-list">
															{/* Start Single List  */}
															<div className="resume-single-list">
																<div className="inner">
																	<div className="heading">
																		<div className="title">
																			<h4>Lead Full Stack Developer</h4>
																			<span>
																				Credence Rewards (2022 - Present)
																			</span>
																		</div>
																		<div className="date-of-time">
																			<span>4.30/5</span>
																		</div>
																	</div>
																	<p className="description">
																		Architected and developed a B2B Loyalty Management & Voucher Gifting platform managing USD 15M annually. Built multiple admin panels and APIs for various clients. Integrated payment gateways and deployed projects on AWS.
																	</p>
																</div>
															</div>
															{/* End Single List  */}
															{/* Start Single List  */}
															<div className="resume-single-list">
																<div className="inner">
																	<div className="heading">
																		<div className="title">
																			<h4> Backend Developer</h4>
																			<span>
																				Pragroot Solutions (2021 - 2022)
																			</span>
																		</div>
																		<div className="date-of-time">
																			<span>4.50/5</span>
																		</div>
																	</div>
																	<p className="description">
																		Enhanced skills in Python, React.js, Django, and Django REST Framework. Delivered high-quality web solutions and managed databases using SQL and PostgreSQL.
																	</p>
																</div>
															</div>
															{/* End Single List  */}
															{/* Start Single List  */}
															<div className="resume-single-list">
																<div className="inner">
																	<div className="heading">
																		<div className="title">
																			<h4>Django Developer</h4>
																			<span>
																				ToXSL Technologies Pvt Ltd (2020 - 2021)
																			</span>
																		</div>
																		<div className="date-of-time">
																			<span>4.80/5</span>
																		</div>
																	</div>
																	<p className="description">
																		{" "}
																		Developed a strong foundation in Django, Python, and DRF. Gained hands-on experience with frontend technologies and database management. Worked on multiple projects, contributing to technical and soft skills development.
																	</p>
																</div>
															</div>
															{/* End Single List  */}
														</div>
													</div>
												</div>
												{/* End Skill List Area  */}
												{/* Start Skill List Area 2nd  */}
												<div className="col-lg-6 col-md-12 col-12 mt_md--60 mt_sm--60">
													<div className="content">
														<span className="subtitle"> &nbsp;</span>
														<h4 className="maintitle"> &nbsp; </h4>
														<div className="experience-list">
															{/* Start Single List  */}
															<div className="resume-single-list">
																<div className="inner">
																	<div className="heading">
																		<div className="title">
																			<h4>Freelance Developer</h4>
																			<span>2020 - Present</span>
																		</div>
																		<div className="date-of-time">
																			<span>4.70/5</span>
																		</div>
																	</div>
																	<p className="description">
																		Worked on various freelance projects, including e-commerce websites, job search platforms, and admin panels. Focused on end-to-end development, API integration, and deployment on cloud platforms.
																	</p>
																</div>
															</div>
															{/* End Single List  */}
															{/* Start Single List  */}
															<div className="resume-single-list">
																<div className="inner">
																	<div className="heading">
																		<div className="title">
																			<h4>Personal Projects</h4>
																			<span>
																				Personal (2019 - 2020)
																			</span>
																		</div>
																		<div className="date-of-time">
																			<span>4.95/5</span>
																		</div>
																	</div>
																	<p className="description">
																		Developed a movie list web application similar to Hotstar. Implemented features like movie categorization, user authentication, and search functionality. Utilized Django for backend and React.js for the frontend, ensuring a seamless and responsive user experience.
																	</p>
																</div>
															</div>
															{/* End Single List  */}
															{/* Start Single List  */}
															<div className="resume-single-list">
																<div className="inner">
																	<div className="heading">
																		<div className="title">
																			<h4>Start Ups</h4>
																			<span>
																				Created Start Ups (2020 - 2024)
																			</span>
																		</div>
																		<div className="date-of-time">
																			<span>5.00/5</span>
																		</div>
																	</div>
																	<p className="description">
																		Founded a travel agency specializing in curated tour packages to Kashmir, Co-founded an innovative marriage bureau app, Established a car garage service that offers on-demand repair and maintenance services.
																	</p>
																</div>
															</div>
															{/* End Single List  */}
														</div>
													</div>
												</div>
												{/* End Skill List Area  */}
											</div>
										</div>
									</div>
									{/* End Single Tab  */}

								</div>
							</div>
						</div>
					</div>
				</div>
				{/* End Resume Area */}
				{/* Start Testimonia Area  */}
				<div
					className="rn-testimonial-area rn-section-gap section-separator"
					id="testimonial"
				>
					<div className="container">
						<div className="row">
							<div className="col-lg-12">
								<div
									data-aos="fade-up"
									data-aos-duration={500}
									data-aos-delay={100}
									data-aos-once="true"
									className="section-title text-center"
								>
									<span className="subtitle">What Clients Say</span>
									<h2 className="title">Testimonial</h2>
								</div>
							</div>
						</div>
						<div
							className="row"
							data-aos="fade-up"
							data-aos-duration={500}
							data-aos-delay={300}
							data-aos-once="true"
						>
							<div className="col-lg-12">
								<div className="testimonial-activation testimonial-pb mb--30">
									{/* Start Single testiminail */}
									<div className="testimonial mt--50 mt_md--40 mt_sm--40">
										<div className="inner">
											<div className="card-info">
												<div className="card-thumbnail">
													<img
														src={final_home_1st}
														alt="Testimonial-image"
													/>
												</div>
												<div className="card-content">
													<span className="subtitle mt--10">
														InnovateX
													</span>
													<h3 className="title">Jane Smith</h3>
													<span className="designation">
														Product Manager
													</span>
												</div>
											</div>
											<div className="card-description">
												<div className="title-area">
													<div className="title-info">
														<h3 className="title">API Development and Integration</h3>
														<span className="date">
															via Upwork - Mar 4, 2023 - Aug 30, 2023
														</span>
													</div>
													<div className="rating">
														<img src={rating} alt="rating-image" />
														<img src={rating} alt="rating-image" />
														<img src={rating} alt="rating-image" />
														<img src={rating} alt="rating-image" />
														<img src={rating} alt="rating-image" />
													</div>
												</div>
												<div className="seperator" />
												<p className="discription">
													Mukhtar Ahmad Zargar created and integrated the RESTful APIs for our mobile app, HealthTrack. The project was completed on time, and the APIs were well-documented and performed flawlessly. His expertise made a significant impact on our project's success.
												</p>
											</div>
										</div>
									</div>
									{/*End Single testiminail */}
									{/* Start Single testiminail */}
									<div className="testimonial mt--50 mt_md--40 mt_sm--40">
										<div className="inner">
											<div className="card-info">
												<div className="card-thumbnail">
													<img
														src={final_home_2nd}
														alt="Testimonial-image"
													/>
												</div>
												<div className="card-content">
													<span className="subtitle mt--10">
														FinServe
													</span>
													<h3 className="title">Michael Brown</h3>
													<span className="designation">CTO</span>
												</div>
											</div>
											<div className="card-description">
												<div className="title-area">
													<div className="title-info">
														<h3 className="title">Cloud Deployment and Management</h3>
														<span className="date">
															Direct Contract - June 4, 2022 - Mar 30, 2023
														</span>
													</div>
													<div className="rating">
														<img src={rating} alt="rating-image" />
														<img src={rating} alt="rating-image" />
														<img src={rating} alt="rating-image" />
														<img src={rating} alt="rating-image" />
														<img src={rating} alt="rating-image" />
													</div>
												</div>
												<div className="seperator" />
												<p className="discription">
													We engaged Mukhtar Ahmad Zargar for deploying our financial analytics platform, FinInsight, on AWS. He handled the entire process, from server configuration to security protocols, ensuring a smooth and scalable deployment. His professionalism and technical skills are top-notch.
												</p>
											</div>
										</div>
									</div>
									{/*End Single testiminail */}
									{/* Start Single testiminail */}
									<div className="testimonial mt--50 mt_md--40 mt_sm--40">
										<div className="inner">
											<div className="card-info">
												<div className="card-thumbnail">
													<img
														src={final_home_3rd}
														alt="Testimonial-image"
													/>
												</div>
												<div className="card-content">
													<span className="subtitle mt--10">TechSolutions</span>
													<h3 className="title">Nevine Dhawan</h3>
													<span className="designation">CEO</span>
												</div>
											</div>
											<div className="card-description">
												<div className="title-area">
													<div className="title-info">
														<h3 className="title">Full Stack Development</h3>
														<span className="date">
															Freelancer Team - Aug 4, 2023 - Mar 30, 2024
														</span>
													</div>
													<div className="rating">
														<img src={rating} alt="rating-image" />
														<img src={rating} alt="rating-image" />
														<img src={rating} alt="rating-image" />
														<img src={rating} alt="rating-image" />
														<img src={rating} alt="rating-image" />
													</div>
												</div>
												<div className="seperator" />
												<p className="discription">
													Working with Mukhtar Ahmad Zargar was a fantastic experience. He developed our e-commerce platform, ShopEase, from scratch, ensuring a seamless user experience and robust backend functionality. His attention to detail and commitment to quality were exceptional.
												</p>
											</div>
										</div>
									</div>
									{/*End Single testiminail */}

								</div>
							</div>
						</div>
					</div>
				</div>
				{/* End Testimonia Area  */}

				{/* Start News Area */}
				<div
					className="rn-blog-area rn-section-gap section-separator"
					id="blog"
				>
					<div className="container">
						<div className="row">
							<div className="col-lg-12">
								<div
									data-aos="fade-up"
									data-aos-duration={500}
									data-aos-delay={100}
									data-aos-once="true"
									className="section-title text-center"
								>
									<span className="subtitle">
										Visit my blog and keep your feedback
									</span>
									<h2 className="title">My Blog</h2>
								</div>
							</div>
						</div>
						<div className="row row--25 mt--30 mt_md--10 mt_sm--10">
							{/* Start Single blog */}
							<div
								data-aos="fade-up"
								data-aos-duration={500}
								data-aos-delay={100}
								data-aos-once="true"
								className="col-lg-6 col-xl-4 mt--30 col-md-6 col-sm-12 col-12 mt--30"
							>
								<div
									className="rn-blog"
									data-bs-toggle="modal"
									data-bs-target="#blog1modal"
								>
									<div className="inner">
										<div className="thumbnail">
											<a href="javascript:void(0)">
												<img
													src={portfolio_01}
													alt="Personal Portfolio Images"
												/>
											</a>
										</div>
										<div className="content">
											<div className="category-info">
												<div className="category-list">
													<a href="javascript:void(0)">Development</a>
												</div>
												<div className="meta">
													<span>
														<i className="feather-clock" /> 2 min read
													</span>
												</div>
											</div>
											<h4 className="title">
												<a href="javascript:void(0)">
													Building Scalable Web Applications with Django and React.js
													<i className="feather-arrow-up-right" />
												</a>
											</h4>
										</div>
									</div>
								</div>
								{/* Modal Blog Body area Start */}
								<div
									className="modal fade"
									id="blog1modal"
									tabIndex={-1}
									role="dialog"
									aria-hidden="true"
								>
									<div
										className="modal-dialog modal-dialog-centered modal-news"
										role="document"
									>
										<div className="modal-content">
											<div className="modal-header">
												<button
													type="button"
													className="close"
													data-bs-dismiss="modal"
													aria-label="Close"
												>
													<span aria-hidden="true">
														<i data-feather="x" />
													</span>
												</button>
											</div>
											{/* End of .modal-header */}
											<div className="modal-body">
												<img
													src={portfolio_01}
													alt="news modal"
													className="img-fluid modal-feat-img"
												/>
												<div className="news-details">
													<span className="date">2 May, 2023</span>
													<h2 className="title">
														Building Scalable Web Applications with Django and React.js
													</h2>
													<p>
														Discover the best practices for developing scalable web applications using Django for the backend and React.js for the frontend. This blog covers architecture design, state management, and optimizing performance to handle high traffic.													</p>

												</div>
											</div>
											{/* End of .modal-body */}
										</div>
									</div>
								</div>
								{/* End Modal Blog area */}
							</div>
							{/* End Single blog */}
							{/* Start Single blog */}
							<div
								data-aos="fade-up"
								data-aos-duration={500}
								data-aos-delay={150}
								data-aos-once="true"
								className="col-lg-6 col-xl-4 mt--30 col-md-6 col-sm-12 col-12 mt--30"
							>
								<div
									className="rn-blog"
									data-bs-toggle="modal"
									data-bs-target="#blog2modal"
								>
									<div className="inner">
										<div className="thumbnail">
											<a href="javascript:void(0)">
												<img
													src={deployment_img}
													alt="Personal Portfolio Images"
												/>
											</a>
										</div>
										<div className="content">
											<div className="category-info">
												<div className="category-list">
													<a href="javascript:void(0)">Deployment</a>
												</div>
												<div className="meta">
													<span>
														<i className="feather-clock" /> 10 min read
													</span>
												</div>
											</div>
											<h4 className="title">
												<a href="javascript:void(0)">
													Deploying Django Applications on AWS with NGINX and Gunicorn
													<i className="feather-arrow-up-right" />
												</a>
											</h4>
										</div>
									</div>
								</div>
								{/* Modal Blog Body area Start */}
								<div
									className="modal fade"
									id="blog2modal"
									tabIndex={-1}
									role="dialog"
									aria-hidden="true"
								>
									<div
										className="modal-dialog modal-dialog-centered modal-news"
										role="document"
									>
										<div className="modal-content">
											<div className="modal-header">
												<button
													type="button"
													className="close"
													data-bs-dismiss="modal"
													aria-label="Close"
												>
													<span aria-hidden="true">
														<i data-feather="x" />
													</span>
												</button>
											</div>
											{/* End of .modal-header */}
											<div className="modal-body">
												<img
													src={deployment_img}
													alt="news modal"
													className="img-fluid modal-feat-img"
												/>
												<div className="news-details">
													<span className="date">2 June, 2024</span>
													<h2 className="title">
														Deploying Django Applications on AWS with NGINX and Gunicorn
													</h2>
													<p>
														A step-by-step guide on deploying Django applications on AWS using NGINX and Gunicorn. Learn about setting up the server, configuring security, and ensuring your application is scalable and reliable.
													</p>

												</div>
											</div>
											{/* End of .modal-body */}
										</div>
									</div>
								</div>
								{/* End Modal Blog area */}
							</div>
							{/* End Single blog */}
							{/* Start Single blog */}
							<div
								data-aos="fade-up"
								data-aos-duration={500}
								data-aos-delay={200}
								data-aos-once="true"
								className="col-lg-6 col-xl-4 mt--30 col-md-6 col-sm-12 col-12 mt--30"
							>
								<div
									className="rn-blog"
									data-bs-toggle="modal"
									data-bs-target="#blog3modal"
								>
									<div className="inner">
										<div className="thumbnail">
											<a href="javascript:void(0)">
												<img
													src={blog_03}
													alt="Personal Portfolio Images"
												/>
											</a>
										</div>
										<div className="content">
											<div className="category-info">
												<div className="category-list">
													<a href="javascript:void(0)">Integration</a>
												</div>
												<div className="meta">
													<span>
														<i className="feather-clock" /> 5 min read
													</span>
												</div>
											</div>
											<h4 className="title">
												<a href="javascript:void(0)">
													Integrating Payment Gateways in Web Applications
													<i className="feather-arrow-up-right" />
												</a>
											</h4>
										</div>
									</div>
								</div>
								{/* Modal Blog Body area Start */}
								<div
									className="modal fade"
									id="blog3modal"
									tabIndex={-1}
									role="dialog"
									aria-hidden="true"
								>
									<div
										className="modal-dialog modal-dialog-centered modal-news"
										role="document"
									>
										<div className="modal-content">
											<div className="modal-header">
												<button
													type="button"
													className="close"
													data-bs-dismiss="modal"
													aria-label="Close"
												>
													<span aria-hidden="true">
														<i data-feather="x" />
													</span>
												</button>
											</div>
											{/* End of .modal-header */}
											<div className="modal-body">
												<img
													src={blog_big_01}
													alt="news modal"
													className="img-fluid modal-feat-img"
												/>
												<div className="news-details">
													<span className="date">2 Aug, 2023</span>
													<h2 className="title">
														Integrating Payment Gateways in Web Applications
													</h2>
													<p>
														This blog explores the process of integrating popular payment gateways like Stripe and PayPal into web applications. It covers API integration, handling transactions securely, and providing a seamless checkout experience for users.													</p>

												</div>
											</div>
											{/* End of .modal-body */}
										</div>
									</div>
								</div>
								{/* End Modal Blog area */}
							</div>
							{/* End Single blog */}
						</div>
					</div>
				</div>
				{/* ENd Mews Area */}
				{/* Start Contact section */}
				<div
					className="rn-contact-area rn-section-gap section-separator"
					id="contacts"
				>
					<div className="container">
						<div className="row">
							<div className="col-lg-12">
								<div className="section-title text-center">
									<span className="subtitle">Contact</span>
									<h2 className="title">Contact With Me</h2>
								</div>
							</div>
						</div>
						<div className="row mt--50 mt_md--40 mt_sm--40 mt-contact-sm">
							<div className="col-lg-5">
								<div className="contact-about-area">
									<div className="thumbnail">
										<img src={contact1} alt="contact-img" />
									</div>
									<div className="title-area">
										<h4 className="title">Mukhtar Ahmad Zargar</h4>
										<span>Lead Full Stack Developer</span>
									</div>
									<div className="description">
										<p>
											I am available for freelance work. Connect with me via and
											call in to my account.
										</p>
										<span className="phone">
											Phone: <a href="tel:01941043264">+0000000000</a>
										</span>
										<span className="mail">
											Email:{" "}
											<a href="mailto:contact@mukhtarzargar.com">contact@mukhtarzargar.com</a>&nbsp; <small>(Recommended)</small>
										</span>
									</div>
									<div className="social-area">
										<div className="name">FIND WITH ME</div>
										<div className="social-icone">
											<a target="_blank" href="https://github.com/Maan20">
												<img src={facebook_icon} width="50%" />
											</a>
											<a target="_blank" href="https://www.linkedin.com/in/mukhtar-ahmad-zargar/">
												<img src={linked_in_icon} />
											</a>
											<a target="_blank" href="https://www.instagram.com/maaan_g/">
												<img src={instagram_icon} />
											</a>
										</div>
									</div>
								</div>
							</div>
							<div data-aos-delay={600} className="col-lg-7 contact-input">
								<div className="contact-form-wrapper">
									<div className="introduce">
										<form
											className="rnt-contact-form rwt-dynamic-form row"
											id="contact-form"
											method="POST"
											action="mail.php"
										>
											<div className="col-lg-6">
												<div className="form-group">
													<label htmlFor="contact-name">Your Name</label>
													<input
														className="form-control form-control-lg"
														name="contact-name"
														id="contact-name"
														type="text"
													/>
												</div>
											</div>
											<div className="col-lg-6">
												<div className="form-group">
													<label htmlFor="contact-phone">Phone Number</label>
													<input
														className="form-control"
														name="contact-phone"
														id="contact-phone"
														type="text"
													/>
												</div>
											</div>
											<div className="col-lg-12">
												<div className="form-group">
													<label htmlFor="contact-email">Email</label>
													<input
														className="form-control form-control-sm"
														id="contact-email"
														name="contact-email"
														type="email"
													/>
												</div>
											</div>
											<div className="col-lg-12">
												<div className="form-group">
													<label htmlFor="subject">subject</label>
													<input
														className="form-control form-control-sm"
														id="subject"
														name="subject"
														type="text"
													/>
												</div>
											</div>
											<div className="col-lg-12">
												<div className="form-group">
													<label htmlFor="contact-message">Your Message</label>
													<textarea
														name="contact-message"
														id="contact-message"
														cols={30}
														rows={10}
														defaultValue={""}
													/>
												</div>
											</div>
											<div className="col-lg-12">
												<button
													name="submit"
													type="submit"
													id="submit"
													className="rn-btn"
												>
													<span>SEND MESSAGE</span>
													<i data-feather="arrow-right" />
												</button>
											</div>
										</form>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				{/* End Contuct section */}
				{/* Modal Portfolio Body area Start */}
				<div
					className="modal fade"
					id="exampleModalCenter"
					tabIndex={-1}
					role="dialog"
					aria-hidden="true"
				>
					<div className="modal-dialog modal-dialog-centered" role="document">
						<div className="modal-content">
							<div className="modal-header">
								<button
									type="button"
									className="close"
									data-bs-dismiss="modal"
									aria-label="Close"
								>
									<span aria-hidden="true">
										<i data-feather="x" />
									</span>
								</button>
							</div>
							<div className="modal-body">
								<div className="row align-items-center">
									<div className="col-lg-6">
										<div className="portfolio-popup-thumbnail">
											<div className="image">
												<img
													className="w-100"
													src={portfolio_04}
													alt="slide"
												/>
											</div>
										</div>
									</div>
									<div className="col-lg-6">
										<div className="text-content">
											<h3>
												<span>Featured - Design</span> Development to Deployment of E-commerce Websites.
											</h3>
											<p className="mb--30">
												Specializing in creating robust and scalable e-commerce websites using Django and React.js. Focus on seamless user experience and efficient transaction handling.
											</p>

											<div className="button-group mt--20">
												<a href="#" className="rn-btn thumbs-icon">
													<span>LIKE THIS</span>
													<i data-feather="thumbs-up" />
												</a>
												<a href="#" className="rn-btn">
													<span>VIEW PROJECT</span>
													<i data-feather="chevron-right" />
												</a>
											</div>
										</div>
										{/* End of .text-content */}
									</div>
								</div>
								{/* End of .row Body*/}
							</div>
						</div>
					</div>
				</div>
				{/* End Modal Portfolio area */}

				{/* Back to  top Start */}
				<div className="backto-top">
					<div>
						<i data-feather="arrow-up" />
					</div>
				</div>
				{/* Back to top end */}


			</main>
			{/* Start Footer Area */}
			<div className="rn-footer-area rn-section-gap section-separator">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="footer-area text-center">
								<div className="logo">
									<a href="index.html">
										<img src={logo} alt="logo" />
										<h3 className="text-dark">Mukhtar Ahmad Zargar</h3>
									</a>
								</div>
								<p className="description mt--30">
									© 2023. All rights reserved by
									Mukhtar Ahmad Zargar
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* End Footer Area */}
		</>
	);
}

export default Body;
