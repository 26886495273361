import Body from './Body';
import './App.css';

function App() {
  return (
    <>
    <Body />
    </>
  );
}

export default App;
